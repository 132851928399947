<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <app-breadcrumb :breadcrumbs="breadcrumbs"/>
      <div class="content-body">
        <section>
          <div class="row">
            <div class="col-12 col-xl-9 col-md-8">
              <div class="card">
                <div class="card-body">
                  <div class="row" style="height: 350px">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="title">Категория товара *</label>
                        <TreeSelect
                            id="category"
                            v-model="form.category_id"
                            :options="categories"
                            :searchable="true"
                            :normalizer="normalizer"
                            :always-open="true"
                            :max-height="270"
                            @input="getDictionaries"
                            placeholder="Выберите категорию"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label for="sku" class="d-block">Артикул (SKU)</label>
                        <div>
                          <input
                              id="sku"
                              :disabled="!form.category_id"
                              v-model="form.sku"
                              placeholder="Введите Артикул"
                              class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-8">
                      <div class="form-group">
                        <label for="name" class="d-block">Название</label>
                        <div>
                          <input
                              id="name"
                              :disabled="!form.category_id"
                              v-model="form.name"
                              placeholder="Введите название"
                              class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="url" class="d-block">Описание</label>
                    <div>
                      <textarea type="text" :disabled="!form.category_id" class="form-control" v-model="form.description"/>
                    </div>
                  </div>
                  <ProductOptionsForm @refresh="changeOptions" :categoryId="form.category_id" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 invoice-actions">
              <div class="card">
                <div class="card-body">
                  <button class="btn btn-primary btn-block" @click="createProduct">Сохранить</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TreeSelect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import ProductOptionsForm from '@/views/products/ProductOptionsForm.vue';

export default {
  name: 'ProductCreate',
  components: {
    AppBreadcrumb, TreeSelect, ProductOptionsForm,
  },
  data() {
    return {
      breadcrumbs: [],
      form: {
        category_id: null,
        name: null,
        sku: null,
        description: null,
      },
      translatedInfo: {
        name: null,
        description: null,
        materials: null,
        country: null,
        category: null,
      },
      options: [],
      searchQuery: null,
      categories: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.all_children,
        };
      },
      productOptions: [],
    };
  },
  async mounted() {
    this.categories = (await this.$api.categories.select()).data;
  },
  methods: {
    async getDictionaries() {
      if (!this.form.category_id) {
        this.productOptions = [];
        return;
      }
      this.productOptions = (await this.$api.categories.productOptions(this.form.category_id)).data;
    },
    changeOptions(options) {
      this.options = options;
    },
    async createProduct(e) {
      const error = this.validate();
      if (error) {
        this.$bvToast.toast(error, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
        e.preventDefault();
        return;
      }

      const data = { ...this.form };
      data.options = this.options;

      try {
        const response = await this.$api.products.create(data);
        this.$bvToast.toast('Товар успешно создан', {
          title: 'Успех',
          variant: 'success',
          solid: true,
        });
        await this.$router.push({ name: 'dictionary-product-details', params: { id: response.data } });
      } catch (modalError) {
        this.$bvToast.toast(modalError.response.data.details.message, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }
    },
    validate() {
      if (!this.form.name) {
        return 'Имя товара не может быть пустым';
      }

      if (!this.form.category_id) {
        return 'Категория товара не может быть пустым';
      }

      if (!this.form.sku) {
        return 'Артикул товара не может быть пустым';
      }
      let error = null;

      this.productOptions.filter((option) => option.required).forEach((option) => {
        if (option.input_type === 'multi_select') {
          if (this.options[option.id] === undefined || !this.options[option.id].length) {
            error = `${option.name} не могут быть пустыми`;
          }
        }

        if (option.input_type === 'input') {
          if (this.options[option.id] === undefined || !this.options[option.id]) {
            error = `${option.name} не может быть пустыми`;
          }
        }

        if (option.input_type === 'select') {
          if (this.options[option.id] === undefined || !this.options[option.id]) {
            error = `Поле ${option.name} не может быть пустым`;
          }
        }
      });

      return error;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

</style>
